/**
 * elements example
 *
 * [
 *   { id: 'sfe__certificate__image', style: 'translateY', slow: 10 },
 * ]
 *
 * @param elements
 */
const DEFAULT_SLOWING_DOWN = 10;

export default (elements = []) => {
  const getElementsShift = (elemScrollTopValue, slowingDown) => {
    const shiftValue = elemScrollTopValue / (slowingDown || DEFAULT_SLOWING_DOWN);
    return Math.max(-200, Math.min(200, shiftValue));
  };

  elements.forEach(({ id: elemId, style: transformProp, slow }) => {
    const elem = document.querySelector(`#${elemId}`);
    if (elem) {
      const elementsShift = getElementsShift(elem.getBoundingClientRect().top, slow);
      const shiftMeasuring = transformProp === 'rotate' ? 'deg' : 'px';
      elem.style.transform = `${transformProp}(${elementsShift}${shiftMeasuring})`;
    } else if (!window?.location?.host?.includes('synergy')) {
      // eslint-disable-next-line no-console
      console.error(`Элемент с id #${elemId} не найден!`);
    }
  });
};

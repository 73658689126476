<template>
  <main>
    <com-privacy-modal
      v-if="privacyDialog"
      @close="privacyDialog = false"
    />

    <lead-modal
      v-if="lead1ModalState || lead2ModalState"
      :land-code="landCode"
      for-com
    />
    <scroll-top-button />

    <app-header-school-page for-com />
    <school-page
      :land-code="landCode"
      for-com
    />

    <app-footer-school-page
      for-com
      @openPrivacy="privacyDialog = true"
    />
    <video-modal v-if="videoModalState" />
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LeadModal from '~/components/v2/modals/LeadModal.vue';
import VideoModal from '~/components/v2/modals/VideoModal.vue';
import ScrollTopButton from '~/components/v2/common/ScrollTopButton.vue';
import SchoolPage from '~/components/v2/school/SchoolPageOld.vue';
import ComPrivacyModal from '~/components/v2/modals/ComPrivacyModal.vue';
import AppHeaderSchoolPage from '~/components/v2/school/AppHeaderSchoolPage.vue';
import AppFooterSchoolPage from '~/components/v2/school/AppFooterSchoolPage.vue';

import scrollParallax from '@/utils/scrollParallax';
import HeadMixin from '@/mixins/HeadMixin';

export default {
  name: 'OnlineSchoolCom',
  components: {
    ComPrivacyModal,
    VideoModal,
    ScrollTopButton,
    LeadModal,
    SchoolPage,
    AppHeaderSchoolPage,
    AppFooterSchoolPage,
  },

  mixins: [HeadMixin],
  layout: 'emptyLayout',

  data: () => ({
    privacyDialog: false,
    landCode: 'new_online_school_school_zarubezh',

    // для head
    title: 'Домашняя школа «Синергия». Дистанционное онлайн-обучение с 5 по 11 класс',
    // eslint-disable-next-line max-len,vue/max-len
    description: 'Онлайн-школа «Синергия» — Комфортная и поддерживающая среда, где обучение обретает смысл. Зачисление в лицензированную школу с государственной аккредитацией',
    image: '/v2/school/share-image.jpg',
  }),

  head() {
    return {
      link: [{ rel: 'canonical' }],
      meta: [{ name: 'robots', content: 'noindex' }],
    };
  },

  computed: {
    ...mapGetters('modals', {
      lead1ModalState: 'lead1Modal',
      lead2ModalState: 'lead2Modal',
      videoModalState: 'videoModal',
    }),
  },

  mounted() {
    window.addEventListener('keydown', this.keydownHnd);
    window.addEventListener('resize', this.setWindowSize);
    this.setWindowSize({ target: window });

    this.setAnimationStyles();
    window.addEventListener('scroll', this.setAnimationStyles);

    this.$root.$on('comPrivacy', () => {
      this.privacyDialog = true;
    });

    try {
      this.$fbPixel.init();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('$fbPixel error:', e);
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keydownHnd);
    window.removeEventListener('resize', this.setWindowSize);
    window.removeEventListener('scroll', this.setAnimationStyles);
  },

  methods: {
    ...mapActions({
      setWindowSize: 'setWindowSize',
    }),
    ...mapActions('modals', {
      closeAllModals: 'closeAll',
    }),

    keydownHnd(event) {
      if (event.code === 'Escape') {
        this.closeAllModals();
      }
    },

    setAnimationStyles() {
      const parallaxElements = [
        { id: 'advantages__figure-baza', style: 'translateY' },
        { id: 'advantages__row-blocks__img', style: 'translateX' },
        { id: 'advantages__figure-1', style: 'translateY' },
        { id: 'advantages__figure-2', style: 'translateY' },
        { id: 'school-about__star', style: 'translateY' },
        { id: 'school-about__square', style: 'translateY' },
        { id: 'school-page__workers_flower', style: 'translateY' },
        { id: 'school-way__img-circle-star', style: 'translateY' },
        { id: 'school-way__img-star', style: 'translateY' },
        { id: 'parents-videos--bg-image', style: 'translateY' },
        { id: 'parents-videos__head_earth', style: 'translateY' },
        { id: 'faq-accordion__star-img', style: 'translateY' },
        { id: 'mc-star-top', style: 'translateY' },
        { id: 'mc-star-left', style: 'translateX' },
        { id: 'mc-star-bottom', style: 'translateX' },
        { id: 'mc-star-top-mob', style: 'translateY' },
        { id: 'mc-star-bottom-mob', style: 'translateX' },
        { id: 's-know-arrow', style: 'translateY' },
        { id: 's-know-zip', style: 'translateY' },
      ];

      scrollParallax(parallaxElements);
    },
  },
};
</script>

<style scoped lang="scss">
main * {
  font-family: 'TildaSans', sans-serif;
}

::v-deep button {
  font-family: 'TildaSans', sans-serif;
}

::v-deep input {
  font-family: 'TildaSans', sans-serif !important;
}

// артефакты от старых страниц
::v-deep section {
  margin-bottom: 0;

  h2,
  h3 {
    margin: 0;
  }
}

.school-page {
  display: flex;
  flex-direction: column;
  gap: 180px;
  padding-bottom: 140px;

  background-color: $gray-bg;

  @include media-down(laptop) {
    gap: 140px;
    padding-bottom: 120px;
  }

  @include media-down(tablet) {
    gap: 100px;
  }

  @include media-down(mobile) {
    gap: 80px;
  }

  .school-page__workers {
    position: relative;

    #school-page__workers_flower {
      position: absolute;
      left: 0;
      top: -330px;

      @include media-down(tablet) {
        display: none;
      }
    }
  }

  .school-page__advantages {
    margin-bottom: 180px;

    @include media-down(laptop) {
      margin-bottom: 140px;
    }

    @include media-down(tablet) {
      margin-bottom: 100px;
    }

    @include media-down(mobile) {
      margin-bottom: 80px;
    }
  }

  .school-page__banner {
    padding-bottom: 72px;
    margin-bottom: -72px;
    overflow: hidden;
  }

  &__about {
    margin-bottom: 40px;

    @include media-down(tablet) {
      margin-bottom: 20px;
    }
  }

  &__unique {
    padding-top: 170px;
    padding-bottom: 35px;

    background-color: $white-color;
    background-image:
      url('/v2/main/sfe/background.svg'),
      url('/v2/school/bg.svg');
    background-repeat: repeat, no-repeat;
    background-size: auto, cover;
    background-position: center, center;

    @include media-down(tablet) {
      padding-top: 70px;
      padding-bottom: 25px;

      background-image:
        url('/v2/main/sfe/background.svg'),
        url('/v2/school/bg-mob.svg');
      background-size: 950px, cover;
    }
  }

  &__know {
    margin-bottom: 280px;

    @include media-down(tablet) {
      margin-bottom: 80px;
    }
  }

  &__guidance {
    margin-bottom: 130px;
  }

  &__skills {
    margin-bottom: 150px;

    @include media-down(laptop) {
      margin-bottom: 100px;
    }
  }

  &__route {
    margin-bottom: 270px;

    @include media-down(laptop) {
      margin-bottom: 100px;
    }
  }

  &__team {
    margin-bottom: 225px;

    @include media-down(laptop) {
      margin-bottom: 100px;
    }
  }
}

::v-deep .school-page__way_black-friday {
  display: none;
}
</style>

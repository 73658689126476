import { render, staticRenderFns } from "./AppFooterSchoolPage.vue?vue&type=template&id=4ab60f5a&scoped=true"
import script from "./AppFooterSchoolPage.vue?vue&type=script&lang=js"
export * from "./AppFooterSchoolPage.vue?vue&type=script&lang=js"
import style0 from "./AppFooterSchoolPage.vue?vue&type=style&index=0&id=4ab60f5a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ab60f5a",
  null
  
)

export default component.exports
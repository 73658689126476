<template>
  <div
    class="privacy-modal"
    @click="closeModal"
  >
    <div
      class="privacy-modal__inner"
      @click.stop
    >
      <button
        class="m-btn privacy-modal__close"
        @click="closeModal"
      >
        <img
          src="/v2/modal/close.svg"
          alt="close"
        >
      </button>

      <div>
        <h2 class="title-secondary">
          Политика конфиденциальности
        </h2>
        <p class="text-primary">
          Ваша конфиденциальность очень важна для&nbsp;нас. Мы хотим, чтобы Ваша работа
          в&nbsp;Интернет по&nbsp;возможности была максимально приятной и&nbsp;полезной,
          и&nbsp;Вы совершенно спокойно использовали широчайший спектр информации, инструментов
          и&nbsp;возможностей, которые предлагает Интернет. Персональные данные, собранные
          при&nbsp;регистрации (или&nbsp;в&nbsp;любое другое время) преимущественно используется
          для&nbsp;подготовки Продуктов или&nbsp;Услуг в&nbsp;соответствии с&nbsp;Вашими
          потребностями. Ваша информация не&nbsp;будет передана или&nbsp;продана третьим сторонам.
          Однако мы можем частично раскрывать личную информацию в&nbsp;особых случаях, описанных
          в&nbsp;данной Политике конфиденциальности.
        </p>
        <h3 class="text-main">
          Информация об организации
        </h3>
        <p class="text-primary">
          SYNERGY ADVISORY FACILITY MANAGEMENT SERVICES LLC <br>
          Адрес: 3819, The One Tower - Barsha Heights Tecom, Dubai
        </p>
        <h3 class="text-main">
          Рамки Политики конфиденциальности
        </h3>
        <p class="text-primary">
          Настоящая Политика конфиденциальности (далее&nbsp;— «Политика») применяется
          к&nbsp;информации, полученной через&nbsp;данный сайт, иные сайты, виджеты и&nbsp;другие
          используемые интерактивные средства, на&nbsp;которых есть ссылка на&nbsp;данную Политику
          (далее&nbsp;— «Сайт») от&nbsp;пользователей Сайта (далее&nbsp;— «Пользователи»). <br>
          Нижеследующие правила описывают, как Университет «Синергия» обращается с&nbsp;любой
          информацией, относящейся к&nbsp;прямо или&nbsp;косвенно определенному
          или&nbsp;определяемому физическому лицу (субъекту персональных данных) (далее&nbsp;—
          «Персональные данные»), для&nbsp;целей оказания услуг с&nbsp;использованием Сайта. <br>
          Пользователи включают в&nbsp;себя всех физических лиц, которые
          подключаются к&nbsp;Сайту и&nbsp;используют Сайт. <br>
          Пользователи прямо соглашаются на&nbsp;обработку своих Персональных данных, как это
          описано в&nbsp;настоящей Политике. Обработка означает любое действие (операцию)
          или&nbsp;совокупность действий (операций), совершаемых с&nbsp;использованием средств
          автоматизации или&nbsp;без&nbsp;использования таких средств с&nbsp;Персональными данными,
          включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
          изменение), извлечение, использование, передачу (распространение, предоставление,
          доступ), блокирование, удаление, уничтожение Персональных данных. <br>
          Настоящая Политика конфиденциальности действует с&nbsp;03 марта 2016&nbsp;г.
        </p>
        <h3 class="text-main">
          Контролирующие и&nbsp;обрабатывающие лица
        </h3>
        <p class="text-primary">
          Пользователи соглашаются с&nbsp;тем,&nbsp;что:
        </p>
        <ul class="text-primary">
          <li>
            Пользуясь Сайтом, и&nbsp;принимая условия использования, опубликованные
            на&nbsp;Сайте, пользователь заявляет о&nbsp;своем однозначном согласии
            с&nbsp;обработкой его Персональных данных способами, описанными
            в&nbsp;настоящей Политике.
          </li>
          <li>
            Обработка Персональных данных Пользователей осуществляется Оператором персональных
            данных&nbsp;— Synergy Advisory Facility Management LLC ; ceo@synergyadvisory.ae
          </li>
        </ul>
        <h3 class="text-main">
          С&nbsp;какой целью собираются эти&nbsp;данные
        </h3>
        <p class="text-primary">
          Имя используется для&nbsp;обращения лично к&nbsp;вам, а&nbsp;ваш <nobr>e-mail</nobr>
          для&nbsp;отправки вам писем рассылок, новостей тренинга, полезных материалов,
          коммерческих предложений. Вы можете отказаться от&nbsp;получения писем рассылки
          и&nbsp;удалить из&nbsp;базы данных свои контактные данные в&nbsp;любой момент, кликнув
          на&nbsp;ссылку для&nbsp;отписки, присутствующую в&nbsp;каждом письме.
        </p>
        <h3 class="text-main">
          Сбор Персональных данных
        </h3>
        <p class="text-primary">
          При&nbsp;регистрации на&nbsp;Сайте Пользователи подтверждают свое согласие
          с&nbsp;условиями настоящей Политики и&nbsp;свое согласие на&nbsp;обработку своих
          Персональных данных в&nbsp;соответствии с&nbsp;условиями настоящей Политики, кроме того
          они соглашаются на&nbsp;обработку своих Персональных данных на&nbsp;серверах Университета
          «Синергия», расположенных на&nbsp;территории Российской Федерации. <br>
          Обработка Персональных данных осуществляется не&nbsp;дольше, чем этого требуют цели
          обработки Персональных данных, изложенные в&nbsp;настоящей Политике (за&nbsp;исключением
          случаев, предусмотренных законодательством Российской Федерации).

          Университет «Синергия» может обрабатывать следующие Персональные данные:
        </p>
        <ul class="text-primary">
          <li>адрес электронной почты и&nbsp;иную контактную информацию;</li>
          <li>
            сообщения, направляемые с&nbsp;использованием Сайта, и&nbsp;сообщения,
            направляемые в&nbsp;Университет «Синергия»;
          </li>
          <li>
            иную информацию о&nbsp;взаимодействии Пользователя с&nbsp;Сайтом Университета
            «Синергия», результаты сбора статистики посещений Сайта Университета «Синергия»
            и&nbsp;общедоступной информации о&nbsp;программах и&nbsp;устройствах,
            используемых для&nbsp;такового доступа;
          </li>
          <li>
            дополнительную информацию, которую Университет «Синергия» запрашивает
            у&nbsp;Пользователей для&nbsp;удостоверения их личности.
          </li>
        </ul>
        <h3 class="text-main">
          Как эти&nbsp;данные используются
        </h3>
        <p class="text-primary">
          На&nbsp;сайте используются куки (Cookies) и&nbsp;данные о&nbsp;посетителях сервиса Google
          Analytics. При&nbsp;помощи этих данных собирается информация о&nbsp;действиях посетителей
          на&nbsp;сайте с&nbsp;целью улучшения его содержания, улучшения функциональных
          возможностей сайта и, как следствие, создания качественного контента и&nbsp;сервисов
          для&nbsp;посетителей. Вы можете в&nbsp;любой момент изменить настройки своего браузера
          так, чтобы браузер блокировал все файлы cookie или&nbsp;оповещал об&nbsp;отправке этих
          файлов. Учтите при&nbsp;этом, что некоторые функции и&nbsp;сервисы не&nbsp;смогут
          работать должным образом.
        </p>
        <h3 class="text-main">
          Как эти&nbsp;данные защищаются
        </h3>
        <p class="text-primary">
          Для&nbsp;защиты Вашей личной информации мы используем разнообразные административные,
          управленческие и&nbsp;технические меры безопасности. Наша Компания придерживается
          различных международных стандартов контроля, направленных на&nbsp;операции с&nbsp;личной
          информацией, которые включают определенные меры контроля по&nbsp;защите информации,
          собранной в&nbsp;Интернет. Наших сотрудников обучают понимать и&nbsp;выполнять эти меры
          контроля, они ознакомлены с&nbsp;нашим Уведомлением о&nbsp;конфиденциальности, нормами
          и&nbsp;инструкциями. Тем не&nbsp;менее, несмотря на&nbsp;то, что мы стремимся обезопасить
          Вашу личную информацию, Вы тоже должны принимать меры, чтобы защитить ее. Мы настоятельно
          рекомендуем Вам принимать все возможные меры предосторожности во&nbsp;время пребывания
          в&nbsp;Интернете. Организованные нами услуги и&nbsp;<nobr>веб-сайты</nobr>
          предусматривают меры по&nbsp;защите от&nbsp;утечки, несанкционированного использования
          и&nbsp;изменения информации, которую мы контролируем. Несмотря на&nbsp;то, что мы делаем
          все возможное, чтобы обеспечить целостность и&nbsp;безопасность своей сети и&nbsp;систем,
          мы не&nbsp;можем гарантировать, что наши меры безопасности предотвратят незаконный доступ
          к&nbsp;этой информации хакеров сторонних организаций. <br>
          В&nbsp;случае изменения данной политики конфиденциальности вы сможете прочитать
          об&nbsp;этих изменениях на&nbsp;этой странице или, в&nbsp;особых случаях, получить
          уведомление на&nbsp;свой <nobr>e-mail.</nobr>
        </p>
        <a
          href="//synergy.ru/assets/upload/policy_on_processing_of_personal_data.pdf?date=28-04-17"
          target="_blank"
          class="text-primary"
        >
          Политика в&nbsp;отношении обработки персональных данных.pdf
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComPrivacyModal',

  mounted() {
    document.body.style.overflow = 'hidden';
    window.addEventListener('keydown', this.closeModal);
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto';
    window.removeEventListener('keydown', this.closeModal);
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">

.privacy-modal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  padding-top: 80px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba($white-color, 0.6);
  }

  .privacy-modal__inner {
    position: relative;
    z-index: 2;

    width: min(1070px, calc(100% - 32px));
    height: calc(95vh - 80px);

    padding: 30px;

    border-radius: 20px;
    border: 2px solid $black;
    box-shadow: 4px 4px 0 0 $black;
    background-color: $white-color;
    overflow: auto;

    @include media-down(tablet) {
      padding: 14px;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: #d9d9d9;
    }
  }

  .privacy-modal__close {
    position: absolute;
    z-index: 3;
    right: 20px;
    top: 20px;

    @include media-down(tablet) {
      top: 0;
      right: -4px;

      transform: scale(0.7);
    }

    img {
      border-radius: 50px;
      border: 2px solid $black;
      background-color: $white-color;
      box-shadow: 2px 2px 0 0 $black;

      transition: 200ms;

      &:hover {
        box-shadow: none;
        transform: translate(2px, 2px);
      }
    }
  }

  .title-secondary {
    margin-bottom: 20px;

    @include media-down(tablet) {
      margin-bottom: 16px;
      font-size: 22px;
    }
  }

  .text-main {
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 700;
  }

  .text-primary {
    margin-bottom: 4px;

    @include media-down(tablet) {
      font-weight: 500;
    }
  }

  a {
    color: #fbbd1e;
  }
}

</style>
